/* global config, moment, Promise, angular */

'use strict';

angular.module('managerApp').controller('RefundsController', ($scope, $rootScope, refunds, toast, procedureTools, Auth) => {

    $scope.config = config;
    $scope.hasValidateRefundPermission = Auth.hasPermission('REFUNDS', 'PUT') === true;
    const defaultErrorMsg = 'Une erreur est survenue lors du traitement de votre demande.';

    $scope.pagination = {
        currentPage: 1,
        maxSize: 5,
        itemsPerPage: 25,
        totalItems: 0,
        pageChanged: () => {
            getRefunds();
        }
    };

    $rootScope.$on('get-refunds', (e) => {
        $scope.pagination.filters = e.targetScope.search.filters;
        getRefunds();
    });

    $scope.showSearchPanel = () => {
        $rootScope.$broadcast('show-search-panel');
    };

//------------------------------------------------------------------------------

    $scope.validateRefund = (refund) => {        
        let msg = "Voulez-vous vraiment confirmer l'envoi de remboursement pour la demande N° " + refund.number + " ?";
        if (refund.method !== 'virement-bancaire') {
            msg = "Voulez-vous vraiment exécuter la demande de remboursement N° " + refund.number + " ?";
            msg += "\n\n" + "Attention, l'opération est irréversible pour un remboursement sur la carte bancaire.";
        }
        if (window.confirm(msg)) {
            refunds.update(refund._id, { done: true }).then(() => {
                refund.done = true;
                toast.show('Le remboursement pour la demande N°' + refund.number + ' a bien été enregistré.', 'success');
            }).catch((err) => {
                console.log('>>>>>>>>>>>>>>>>>> error')
                console.log(err);
                toast.show(err && err.message ? err.message : defaultErrorMsg, 'error');
            });
        }
    };

    $scope.cancelRefund = (refund, index) => {
        if (window.confirm('Voulez-vous vraiment annuler la demande de remboursement N°' + refund.number + ' ?')) {
            refunds.delete(refund._id).then(() => {
                $scope.items.splice(index, 1);
                toast.show('La demande de remboursement N°' + refund.number + ' a bien été annulée.', 'success');
            }).catch((err) => {
                console.log(err);
                toast.show(err && err.message ? err.message : defaultErrorMsg, 'error');
            });
        }
    };

    function getRefunds() {
        let params = {};
        if ($scope.pagination.filters) {
            for (let i in $scope.pagination.filters) {
                let value = $scope.pagination.filters[i];
                if (i === 'date_from' || i === 'date_to') {
                    value = moment(value);
                    if (value.isValid()) {
                        params[i] = value.format(config.date.defaultFormat);
                    }
                } else {
                    if (i === 'procedureType' && value === 'all') {
                    } else {
                        params[i] = value;
                    }
                }
            }
        }
        refunds.get(params, $scope.pagination.currentPage, $scope.pagination.itemsPerPage).then((data) => {
            $scope.count = data.count;
            $scope.items = data.items;
            for (let i = 0; i < $scope.items.length; i++) {
                let item = $scope.items[i];
                item.methodLabel = procedureTools.getRefundMethodLabel(item.method);
                item.typeLabel = procedureTools.getRefundTypeLabel(item.type);
            }
            $scope.pagination.totalItems = data.count;
            setTimeout(() => {
                $scope.$apply();
            });
        }).catch((err) => {
            $scope.error = err;
        });
    }

    $scope.csvExport = () => {
        refunds.getCSVExport().then((data) => {
            if (data.length > 0) {
                const blob = new Blob([data], {type: 'text/csv'});
                const a = document.createElement('a');
                a.setAttribute('download', 'remboursements.csv');
                a.setAttribute('href', window.URL.createObjectURL(blob));
                a.click();
            } else {
                toast.show("Il n'y a rien à télécharger.", 'error');
            }
        }).catch((err) => {
            console.log(err);
            toast.show(err && err.message ? err.message : defaultErrorMsg, 'error');
        });
    };

    getRefunds();

});
